import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AOS from 'aos';
import "aos/dist/aos.css";
import React, { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import NotFound from "./pages/NotFound";


function App() {
  useEffect(() => {
    AOS.init({
      // Global settings (optional)
      duration: 800, // values from 0 to 3000, with step 50ms
    });
  }, []);

  return (
    <>
      <Navbar />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <Footer />
    </>
  )
}

export default App;
