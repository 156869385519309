import React from 'react'

const Stats = () => {
    const stats = [
        {
            index: 1,
            data: 5,
            title: "Years of Trust"
        },
        {
            index: 2,
            data: "35k",
            title: "Happy Customers"
        },
        {
            index: 3,
            data: 150,
            title: "Products in Line"
        },
    ]

    return (
        <div>
            <section className="relative py-28 bg-gray-900">
                <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                    <div
                        className="z-0 absolute inset-0 my-auto h-[500px]"
                        style={{
                            background: "linear-gradient(152.92deg, #07c8f933 4.54%, #007bff42 34.2%, #C084FC19 77.55%)", filter: "blur(118px)"
                        }}
                    >

                    </div>
                    <div className="z-10 max-w-2xl mx-auto text-center">
                        <h3 className="text-white text-3xl font-bold sm:text-4xl">
                            Customer Satisfaction, Our Priority
                        </h3>
                        <p className="mt-3 text-gray-300">
                            Our commitment to quality and excellence ensures that we consistently exceed expectations, delivering unmatched customer satisfaction.
                        </p>
                    </div>
                    <div className="mt-12">
                        <ul className="flex flex-col gap-16 items-center justify-center sm:flex-row  mx-28 md:mx-0">
                            {
                                stats.map((item, idx) => (
                                    <li key={idx} data-aos="zoom-out-up" data-aos-delay={150*item.index} className="w-full text-center bg-gray-900 sm:px-2 md:px-12 py-4 rounded-lg sm:w-auto">
                                        <h4 className="text-4xl text-white font-semibold">{item.data}+</h4>
                                        <p className="mt-3 text-gray-400 font-medium">{item.title}</p>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Stats