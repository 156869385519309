import React from 'react'
import { useNavigate } from 'react-router-dom';

const Hero = () => {
    const navigate = useNavigate();

    return (
        <div className='pb-20'>
            <section className="py-28">
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
                    <div data-aos="zoom-out-up" className="flex-none space-y-5 px-4 sm:max-w-lg md:px-4 lg:max-w-xl">
                        <h1 className="text-6xl text-cyan-500 font-extrabold">
                            R K Trading Co.
                        </h1>
                        <h2 className="text-4xl text-gray-500 font-bold md:text-5xl">
                            Quality chemicals &
                            <br /> 
                            solvents, trusted
                            <br /> 
                            by industry
                        </h2>
                        <p>
                            Elevating standards in the chemical and solvent industry with a focus on quality, safety, and sustainability
                        </p>
                        <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
                            <a data-aos="zoom-out-up" data-aos-delay="400" target="_blank" rel="noreferrer" className="block py-2 px-4 text-center text-white font-medium bg-cyan-500 duration-150 hover:bg-cyan-400 active:bg-cyan-600 rounded-lg shadow-lg hover:shadow-none cursor-pointer" onClick={() => navigate('/products')}>
                                Browse Products
                            </a>
                            <a data-aos="zoom-out-up" data-aos-delay="800" href="https://www.rktrading.in/why-us.html" target="_blank" rel="noreferrer" className="flex items-center justify-center gap-x-2 py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg md:inline-flex">
                                Why us?
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div data-aos="zoom-out-up" className="flex-none mt-14 md:mt-0 md:max-w-xl">
                        <img
                            src="hero.avif"
                            className=" md:rounded-tl-[108px]"
                            alt=""
                        />
                    </div>

                    {/* Gradient Background */}
                    <div className="absolute inset-x-0 bottom-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-[20rem]" aria-hidden="true">
                        <div
                            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#80ffe8] to-[#89adfc] opacity-30 sm:right-[calc(50%-40rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        ></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Hero