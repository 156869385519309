import React from 'react'

const Industries = () => {
    const features = [
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M3 16h18M3 8h18M7 4h10a2 2 0 012 2v12a2 2 0 01-2 2H7a2 2 0 01-2-2V6a2 2 0 012-2z" />
                </svg>,
            title: "Quality Food Additives",
            desc: "Supplying essential chemicals and additives that enhance food production, improve shelf life, and elevate flavor profiles."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3.75l-4.5 4.5a2.25 2.25 0 000 3.18l9 9a2.25 2.25 0 003.18 0l4.5-4.5a2.25 2.25 0 000-3.18l-9-9a2.25 2.25 0 00-3.18 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 11.25l4.5-4.5M12 6.75L8.25 10.5" />
                </svg>
            ,
            title: "Pharmaceutical Ingredients",
            desc: "Providing high-grade chemicals and intermediates critical for drug manufacturing, research, and development of innovative therapies."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 3h12l3 9-3 9H6l-3-9 3-9z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 3v6h12V3m-9 0h3" />
                </svg>
            ,
            title: "Textile Chemicals",
            desc: "Offering a wide range of chemicals used in the dyeing, finishing, and processing of textiles to achieve vibrant colors and desired textures."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 2v10m0 0l-5-5m5 5l5-5M2 12h10m0 0l-5 5m5-5l5 5" />
                </svg>
            ,
            title: "Agrochemicals",
            desc: "Supplying a variety of agrochemicals designed to support crop protection, growth, and yield enhancement through effective fertilizers and pesticides."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 2h12a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2zm3 4h6M9 10h6m-6 4h6" />
                </svg>
            ,
            title: "Cosmetic Ingredients",
            desc: "Providing key ingredients for the formulation of personal care and cosmetic products, ensuring quality, efficacy, and safety for consumers."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M3 6h18M3 18h18M3 6l6 6-6 6" />
                </svg>
            ,
            title: "Construction Chemicals",
            desc: "Supplying innovative chemicals that enhance the properties, durability, and performance of construction materials, including adhesives and sealants."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 11h18l-1.5 7.5H4.5L3 11zM5 8a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm10 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0zM5 16a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm10 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0z" />
                </svg>
            ,
            title: "Automotive Chemicals",
            desc: "Offering essential chemicals used in the manufacturing, maintenance, and performance improvement of automotive parts and systems."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 6h9a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3zM9 2h6a1 1 0 0 1 1 1v1H8V3a1 1 0 0 1 1-1zM8 4h8v1H8V4z" />
                </svg>
            ,
            title: "Plastic Production Materials",
            desc: "Providing high-quality raw materials and additives necessary for the production and enhancement of plastics and polymers for various applications."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 2C10.355 2 9 3.355 9 5c0 4.97 3 7.837 3 7.837S12 9.966 12 5c0-1.645-1.355-3-3-3 0 0 0 0 0 0 1.195 0 2.269.646 2.873 1.5A6.933 6.933 0 0112 2zM5 8c-1.645 0-3 1.355-3 3s1.355 3 3 3c0 0 0 0 0 0-2.686 0-5 2.017-5 4.5S5 20 7 20h10c2 0 5-1.35 5-4.5S17.686 12 15 12c1.645 0 3-1.355 3-3s-1.355-3-3-3H5z" />
                </svg>
            ,
            title: "Water Purity Chemicals",
            desc: "Supplying specialized chemicals that ensure the purity, safety, and quality of water across different treatment and purification processes."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 2c1.042 0 2.08.397 2.828 1.172l1.415 1.414c1.56 1.56 1.56 4.095 0 5.656l-8.485 8.485c-1.56 1.56-4.095 1.56-5.656 0L2 16.586c-1.56-1.56-1.56-4.095 0-5.656l1.414-1.415C4.42 9.076 5.455 9 6.293 9h.001c1.042 0 2.08.397 2.828 1.172l.707.707a1.5 1.5 0 002.121 0l2.828-2.828a1.5 1.5 0 000-2.121l-.707-.707C11.923 2.397 10.877 2 10.036 2H12z" />
                </svg>
            ,
            title: "Paints and Coatings",
            desc: "Offering a comprehensive range of chemicals that improve the quality, durability, and performance of paints and coatings for diverse applications."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 2a9 9 0 100 18 9 9 0 000-18zm0 3.5c1.378 0 2.5 1.122 2.5 2.5S13.378 10.5 12 10.5 9.5 9.378 9.5 8 10.622 5.5 12 5.5zm0 12c-1.378 0-2.5-1.122-2.5-2.5S10.622 13.5 12 13.5s2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                </svg>
            ,
            title: "Other Diverse Solutions",
            desc: "Providing a wide array of chemical solutions tailored to meet the unique needs of various industries, ensuring quality and compliance with regulatory standards."
        }
    ]

    return (
        <section className="py-24">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-500 md:px-8">
                <div className="max-w-xl mx-auto space-y-3 sm:text-center">
                    <h3 className="text-gray-600 text-3xl font-bold sm:text-4xl">
                        Industries served
                    </h3>
                    <p>
                        Do more with less complexity
                    </p>
                </div>
                <div className="mt-12 sm:px-2 lg:px-8">
                    <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3" data-aos="flip-left">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="flex gap-x-4">
                                    <div className="flex-none w-12 h-12 bg-cyan-50 text-cyan-600 rounded-lg flex items-center justify-center">
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h4 className="text-lg text-gray-800 font-semibold">
                                            {item.title}
                                        </h4>
                                        <p className="mt-3 text-sm">
                                            {item.desc}
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Industries