import React from 'react'
import ProductItems from '../components/ProductItems'
import Qualities from '../components/Qualities'

const Products = () => {
  return (
    <div>
      <div className="max-w-xl mx-auto space-y-3 sm:text-center mt-32 px-10">
        <h3 className="text-gray-600 text-3xl font-bold sm:text-4xl">
          Our offerings
        </h3>
        <p>
          Solutions for every problem
        </p>
      </div>

      <Qualities />
      <ProductItems />

      <div className="fixed inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#d4d7de_1px,transparent_1px)] [background-size:16px_16px]"></div>
      <div className="absolute inset-0 -z-10 h-full w-full bg-gradient-to-b from-white via-transparent"></div>
    </div>
  )
}

export default Products