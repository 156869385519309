import React from 'react'

const Career = () => {
    return (
        <section className="py-14 max-w-screen-xl mx-auto" id='career'>
            <div className="relative overflow-hidden mx-4 px-4 py-14 rounded-2xl bg-cyan-600 md:px-8 md:mx-8">
                <div className="relative z-10 max-w-xl mx-auto text-center">
                    <div className="space-y-3">

                        <h3 className="text-3xl text-white font-bold">
                            Join our team, Apply now
                        </h3>
                        <p className="text-gray-100 leading-relaxed">
                            Interested in joining a team of global leaders working in chemical industry?
                            <br />
                            Send your resume & cover letter.
                        </p>
                    </div>
                    <div className="mt-6" data-aos="zoom-out-up">
                        <button
                            className="flex items-center gap-2 px-12 py-4 text-cyan-600 duration-150 bg-white rounded-lg hover:bg-gray-100 active:bg-cyan-200 mx-auto font-semibold"
                            onClick={() => window.open('mailto:info@rktradchem.com', '_blank')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
                            </svg>
                            Apply now
                        </button> 
                        <p className="mt-3 max-w-lg text-[15px] text-white sm:mx-auto">
                            We look forward to hearing from you!
                        </p>
                    </div>
                </div>
                <div className="absolute inset-0 w-full h-full" style={{ background: "radial-gradient(268.24deg, rgba(59, 130, 246, 0.76) 50%, rgba(59, 130, 246, 0.545528) 80.61%, rgba(55, 48, 163, 0) 117.35%)" }}></div>
            </div>
        </section>
    )
}

export default Career