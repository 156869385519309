import React from 'react'
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import Faq from "../components/Faq"
import Stats from "../components/Stats";
import Career from "../components/Career";
import Testimonials from "../components/Testimonials";
import Industries from "../components/Industries";
import CeoNote from '../components/CeoNote';

const Home = () => {
  return (
    <>
      <Hero />
      <Stats />
      <Industries />
      <Testimonials />
      <Contact />
      <CeoNote />
      <Faq />
      <Career />
    </>
  )
}

export default Home