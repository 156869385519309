import React from 'react'

const Footer = () => {
    const footerNavs = [
        {
            label: "Company",
            items: [
                {
                    href: 'https://rktradchem.com/#faq',
                    name: 'FAQ'
                },
                {
                    href: 'https://rktradchem.com/#ceonote',
                    name: 'Team'
                },
                {
                    href: 'https://rktradchem.com/#career',
                    name: 'Careers'
                },
            ],
        },
        {
            label: "Resources",
            items: [
                {
                    href: 'https://www.rktrading.in/enquiry.html#:~:text=Contact%20Now-,R%20K%20Trading%20Co.,-Contact%20Person',
                    name: 'Support'
                },
                {
                    href: 'https://www.rktrading.in/why-us.html',
                    name: 'Why us'
                },
                {
                    href: 'https://www.rktrading.in/enquiry.html',
                    name: 'Pricing'
                },
            ],
        },
        {
            label: "About",
            items: [
                {
                    href: 'https://www.rktrading.in/profile.html',
                    name: 'License'
                },
                {
                    href: 'https://www.rktrading.in/sitemap.html',
                    name: 'Sitemap'
                },
                {
                    href: 'https://www.rktrading.in/profile.html',
                    name: 'About us'
                },
            ]
        }
    ]

    return (
        <div className='pt-4 bg-gray-800 mx-auto'>
            <footer className="text-gray-400 px-8 pt-8 pb-4 md:px-12">
                <div className="gap-6 justify-between md:flex">
                    <div className="flex-1">
                        <div className="max-w-xs">
                            <a href="/" target="_self" rel="noopener noreferrer">
                                <img src="wide-logo.png" className="w-32 " />
                            </a>
                            <p className="leading-relaxed mt-2 text-[15px]">
                                Quality chemical solutions, trusted by industry specialized in import & export of high quality products across global markets
                            </p>
                        </div>
                        <label className="block pt-4 pb-2 text-gray-300">
                            Browse products:
                        </label>
                        <a href='/products'>
                        <button
                            className="flex justify-start mx-0 px-3 py-2 bg-transparent duration-150 text-cyan-500 hover:text-cyan-50 rounded-xl border-4 border-cyan-500 hover:bg-cyan-500 active:bg-cyan-200 font-semibold"
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                            </svg>
                            <span className="ml-1">Discover</span>
                        </button>
                            </a>
                    </div>
                    <div className="flex-1 mt-10 space-y-6 items-center justify-between sm:flex md:space-y-0 md:mt-0">
                        {
                            footerNavs.map((item, idx) => (
                                <ul
                                    className="space-y-4"
                                    key={idx}
                                >
                                    <h4 className="text-gray-800 font-medium">
                                        {item.label}
                                    </h4>
                                    {
                                        item.items.map(((el, idx) => (
                                            <li key={idx}>
                                                <a
                                                    href={el.href}
                                                    className="hover:underline hover:text-cyan-600"
                                                >
                                                    {el.name}
                                                </a>
                                            </li>
                                        )))
                                    }
                                </ul>
                            ))
                        }
                    </div>
                </div>
                <div className="mt-8 pt-6 border-t items-center justify-between sm:flex">
                    <div className="mt-4 sm:mt-0">
                        &copy; {new Date().getFullYear()} <span className='font-bold text-cyan-500'>R K Trading</span> | All rights reserved.
                    </div>

                    <div className="">
                        Developed by <a href="https://www.linkedin.com/in/pranjal-barnwal" target='_blank' title="Pranjal Kumar" className="text-gray-300 hover:text-gray-100">Pranjal Studio+</a>
                    </div>
                    
                    <div className="mt-6 sm:mt-0">
                        <ul className="flex items-center space-x-4">
                            <li className="w-10 h-10 border rounded-full flex items-center justify-center overflow-hidden p-2">
                                <a target='_blank' href="https://www.linkedin.com/company/rktradchem">
                                    <img src='icons/linkedin.png' className='w-full h-full object-cover  transition-transform duration-300 ease-in-out hover:scale-115' />
                                </a>
                            </li>

                            <li className="w-10 h-10 border rounded-full flex items-center justify-center overflow-hidden p-2">
                                <a target='_blank' href="/">
                                    <img src='icons/facebook.png' className='w-full h-full object-cover  transition-transform duration-300 ease-in-out hover:scale-115' />
                                </a>
                            </li>

                            <li className="w-10 h-10 border rounded-full flex items-center justify-center overflow-hidden p-2">
                                <a target='_blank' href="https://www.indiamart.com/rktradingvasai/">
                                    <img src='icons/indiamart.png' className='w-full h-full object-cover  transition-transform duration-300 ease-in-out hover:scale-115' />
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
                <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
            </footer>
        </div>
    )
}

export default Footer