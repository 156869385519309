import React from 'react'
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    
    return (
        <div className="h-[60vh] mt-24 z-0 bg-white items-center flex justify-center px-5 lg:px-0">
            <div className="w-[415px] text-center flex-col items-center justify-center mx-auto gap-[100px]">
                <div className="mb-4 md:mb-[56px]">
                    <div className="max z-0 -w-[500px] w-full h-[160px] relative flex justify-center items-center mx-auto">
                        <img src="/404.svg" alt="404" />
                    </div>
                </div>
                <div>
                    <h3 className="text-4xl md:text-[56px] leading-[64px] text-[#1A1C16]">
                        Page Not Found
                    </h3>
                </div>
                <div className="flex flex-col gap-6 mt-3">
                    <div className="text-center">
                        <p className="text-base leading-6 tracking-wider font-sans">
                            The page you are looking for might have been removed had its name
                            changed or is temporarily unavailable.
                        </p>
                    </div>
                    <button
                        data-aos="zoom-out-up"
                        className="flex items-center gap-2 px-12 py-4 text-cyan-600 duration-400 bg-cyan-200 rounded-lg hover:bg-gray-200 active:bg-cyan-200 mx-auto font-semibold"
                        onClick={() => navigate('/')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
                        </svg>
                        Go back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotFound