import React from 'react'

const Contact = () => {
    const contactMethods = [
        {
            index: 1,
            url: "mailto:info@rktradchem.com",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
            ,
            contact: "info@rktradchem.com"
        },
        {
            index: 2,
            url: "tels:+918047655930",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
            ,
            contact: "+91 8047-655-930"
        },
        {
            index: 3,
            url: "https://maps.app.goo.gl/CSEv9f5zM6LMAWjA6",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
            ,
            contact: "R K Trading Co. Shop No. F/89, Raghuleela Mega Mall. First Floor, Poisar Gymkhana Road, Kandivali, Borivali, Mumbai - 400067, Maharashtra, India"
        },
    ]

    return (
        <main className="relative py-24" id='contact'>
            <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#d4d7de_1px,transparent_1px)] [background-size:16px_16px]"></div>
            <div className="absolute inset-0 -z-10 h-full w-full bg-gradient-to-b from-white via-transparent to-white"></div>

            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="max-w-lg mx-auto gap-6 justify-between lg:flex lg:max-w-none">
                    <div className="max-w-lg space-y-3">
                        <h3 className="text-gray-600 text-3xl font-bold sm:text-4xl">
                            Let us know 
                            <br />
                            how we can help
                        </h3>
                        <p className='mt-4 mr-20'>
                            We’re here to help and answer any question you might have, We look forward to hearing from you! Please fill out the form, or us the contact information bellow .
                        </p>
                        <div>
                            <ul className="mt-6 flex flex-wrap gap-x-10 gap-y-6 items-center">
                                {
                                    contactMethods.map((item, idx) => {
                                        return (
                                            <li data-aos="zoom-out-up" data-aos-delay={100 * item.index} key={idx} className="flex items-center gap-x-3">
                                                <a href={item.url} target='_blank'>
                                                    <div className="flex-none text-cyan-400">
                                                        {item.icon}
                                                    </div>
                                                    <p className={item.index === 3 ? 'mr-20' : ''}>{item.contact}</p>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    {/* <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md"> */}
                    <div className="sm:w-full mt-12 overflow-hidden rounded-lg lg:col-span-2 h-[360px]" data-aos="zoom-out-up" data-aos-delay="150">
                        <iframe
                            className="w-full h-full"
                            title={`RK Trad Chem`}
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d492.28549206494006!2d72.84902318163199!3d19.21340692898772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b72aeb5bd387%3A0x4fb72a26290d43f4!2sRaghuleela%20Mega%20Mall%20Kandivali%20West!5e0!3m2!1sen!2sin!4v1730395012550!5m2!1sen!2sin'
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </main>
    )
}

export default Contact